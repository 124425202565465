import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { signIn } from 'next-auth/react';
import { toast } from 'react-toastify';
import User from 'services/UserService';
import { useSiteInfo } from '@/context/SiteInfoContext';
import LoginGoogle from './LoginGoogle';
import LoginShluchim from './LoginShluchim';
import LoginChabad from './LoginChabad';

import LoginCredentials from './LoginCredentials';
import getExceptionMessage from 'utils/getExceptionMessage';
import CheckEmailModal from './CheckEmail';
import { emailVerificationStatus } from '@/constants/emailVerificationStatus';
import AccountSuspensionModal from '../modals/AccountSuspensionModal';

const LoginModal = ({
  closeModal,
  show,
  openRegisterModal,
  showWelcomeModal,
  loginData,
}) => {
  const [error, setError] = useState(null);
  const [verifyUser, setVerifyUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userDisabled, setUserDisabled] = useState(false);

  const router = useRouter();

  const { siteInfo } = useSiteInfo();

  const submitLogin = async (e) => {
    try {
      e.preventDefault();
      setError(null);
      setLoading(true);

      const userData = {
        email: e.target.email.value.trim().toLowerCase(),
        password: e.target.password.value,
      };

      const user = await User.findUserByEmail(userData.email, siteInfo.id);
      if (user && user.provider === 'google') {
        return setError(
          'You are already signed up with Google. Please use Google to sign in.',
        );
      }

      if (
        user &&
        user.status === 'inactive' &&
        user.email_verification_secret
      ) {
        return setVerifyUser(user);
      }

      await User.loginUser(
        userData.email,
        userData.password,
        siteInfo.id,
        0,
        loginData,
      );

      if (
        user.email_verification_secret ===
        emailVerificationStatus.verified_not_logged_in
      ) {
        showWelcomeModal('credentials');
        await User.updateUserInformation(user.id, {
          email_verification_secret: emailVerificationStatus.verified,
        });
      }

      setError(null);
      e.target.reset();
      closeModal();
    } catch (e) {
      if (e.message === 'AccountDisabled') {
        setUserDisabled(true);
      } else {
        setError(
          getExceptionMessage(e ?? 'Unable to Sign Up. Please Try Again'),
        );
        console.error({ e });
      }
    } finally {
      setLoading(false);
    }
  };

  const submitLoginWithGoogle = async () => {
    try {
      await signIn('google', {
        redirect: true,
        callbackUrl: loginData ? loginData.callbackUrl : undefined,
      });
      closeModal();
    } catch (e) {
      closeModal();
      toast.error(e.message ?? 'Unable to Sign In. Please Try Again.');
    }
  };

  const handleResetPasswordClick = () => {
    closeModal();
    router.push('/requestpassword');
  };

  const handleSignUpClick = () => {
    openRegisterModal(loginData);
  };

  const handleModalClose = () => {
    setError(null);
    closeModal();
  };

  return (
    siteInfo && (
      <>
        <Modal
          centered
          show={show}
          onHide={handleModalClose}
          className="auth-popup"
        >
          <Modal.Body>
            <h4>Log In</h4>

            {siteInfo.login_method.includes('google') && (
              <LoginGoogle submitLoginWithGoogle={submitLoginWithGoogle} />
            )}
            {siteInfo.login_method.includes('chabad') && (
              <LoginChabad submitLoginWithGoogle={submitLoginWithGoogle} />
            )}

            {siteInfo.login_method.includes('credentials') && (
              <LoginCredentials
                loading={loading}
                submitLogin={submitLogin}
                handleSignUpClick={handleSignUpClick}
                handleResetPasswordClick={handleResetPasswordClick}
              />
            )}
            {siteInfo.login_method.includes('shluchim') && <LoginShluchim />}

            {error && <p className="forgot-password error">{error}</p>}
            <CheckEmailModal
              userData={verifyUser}
              closeModal={() => {
                setVerifyUser(false);
                closeModal();
              }}
            />

            <AccountSuspensionModal
              show={userDisabled}
              closeModal={() => {
                setUserDisabled(false);
                closeModal();
              }}
            />
          </Modal.Body>
        </Modal>
      </>
    )
  );
};
export default LoginModal;
